import React from 'react'
import { Plus, Minus } from 'phosphor-react'

const FaqItem = ({
  showDescription,
  ariaExpanded,
  item,
  index,
  onClick,
  color = 'brand-blue',
  textColor = 'black',
}) => (
  <div
    className={`faq__question w-full border-b border-${color}`}
    key={item.question}
  >
    <dt>
      <button
        aria-expanded={ariaExpanded}
        aria-controls={`faq${index + 1}_desc`}
        data-qa="faq__question-button"
        className={`text-${color} text-lg py-4 flex justify-between items-center w-full transition-all`}
        onClick={onClick}
        type="button"
      >
        {item.title}
        {ariaExpanded === 'true' ? <Minus /> : <Plus />}
      </button>
    </dt>
    <dd>
      <p>
        <div
          dangerouslySetInnerHTML={{ __html: item.body }}
          className={`pb-4 ${showDescription} text-${textColor}`}
        />
      </p>
    </dd>
  </div>
)

export default FaqItem
