import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import FaqItem from '../FaqItem'

const Faq = ({ items, title, color = 'brand-blue' }) => {
  const [activeIndex, setActiveIndex] = useState()

  const handleClick = index => {
    if (activeIndex === index) {
      setActiveIndex(null)
    } else {
      setActiveIndex(index)
    }
  }

  // No items?
  if (!items || !items.length) {
    return null;
  }

  const renderedQuestionsAnswers = items.map((item, index) => {
    const showDescription = index === activeIndex ? 'show' : 'hidden'
    const fontWeightBold = index === activeIndex ? 'font-weight-bold' : ''
    const ariaExpanded = index === activeIndex ? 'true' : 'false'
    return (
      <FaqItem
        showDescription={showDescription}
        fontWeightBold={fontWeightBold}
        ariaExpanded={ariaExpanded}
        item={item}
        index={index}
        onClick={() => handleClick(index)}
        // eslint-disable-next-line react/no-array-index-key
        key={`faq-item-${index}`}
        color={color}
        textColor={color === 'brand-blue' ? 'black' : color}
      />
    )
  })

  return (
    <div className="mx-auto px-8 md:px-20 max-w-screen-xl mb-10">
      <Helmet>
        <script type="application/ld+json">
          {`{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"Do I need to put a down payment for a personal car lease?","acceptedAnswer":{"@type":"Answer","text":"Qualified leases have the option of making no down payment with no payments for 90 days with the EZ Lease. At D&amp;M we offer no money down on your auto lease and 50% lower payments than financing. Here at D&amp;M Auto Leasing, we have the experience to custom fit whatever plan works best for you."}},{"@type":"Question","name":"What types of vehicles does D&M lease?","acceptedAnswer":{"@type":"Answer","text":"D&amp;M specializes in finding you the car you want for a price you can afford. No matter what type of trim, color or body style, options and integrated gadgets you want in a vehicle, D&amp;M can get it. Fill out a quote form and a D&amp;M representative will work with you to lease any used or new vehicle, any make or model. Check out our vehicles page to get an idea of the types of cars we lease."}},{"@type":"Question","name":"What do MSRP, Residual Value, and Capitalized cost mean?","acceptedAnswer":{"@type":"Answer","text":"MSRP is the suggested retail price recommended by the manufacturer for the sale of an item in all retail outlets. Residual value describes the guaranteed value of the vehicle at the end of the lease. This is the part of the vehicle you don&#8217;t pay for on a lease and is one of the most important benefits. Lease payments are lower because you only pay for the portion of the vehicle you will use. Capital cost refers to the amount that is being financed on a lease. This can include tax, title and license fees or sometimes negative equity from a traded vehicle is rolled in."}}]}`}
        </script>
      </Helmet>
      <h3 className={`text-center text-${color} mb-10`}>{title}</h3>
      <dl className={`border-t border-${color}`}>{renderedQuestionsAnswers}</dl>
    </div>
  )
}

export default Faq
